<template>

  <div style="display: flex; flex-grow: 1; justify-content: center;">

    <div v-if="!this.iframesCount"
         style="flex-grow: 1; align-self: center; border-radius:56px; padding:0.3rem; background: linear-gradient(180deg, rgba(33, 150, 243, 0.4) 10%, rgba(33, 150, 243, 0) 30%); max-width: 600px;">
      <div class="flex justify-content-center h-full w-full m-0 py-7 px-4"
           style="border-radius:53px; background: linear-gradient(180deg, var(--surface-50) 38.9%, var(--surface-0));">
        <div class="grid flex-column align-items-center">

          <h3 class="text-900 font-bold mb-2" style="font-weight: normal !important;">Ввод цифрового документа</h3>

          <div v-if="isNewProcess === null" class="mt-3 mb-3">

            <Button class="p-button p-button-outlined mr-3" label="Начать новый процесс" @click="isNewProcess = true"/>

            <Button class="p-button p-button-outlined" label="Продолжить существующий процесс"
                    @click="continueProcessClicked"/>

          </div>

          <div v-else>

            <div class="my-3" style="text-align: center;">
              <Checkbox v-model="selected.show_no_actual" :binary="true" inputId="show_no_actual"/>
              <label class="ml-2" for="show_no_actual">Показывать архивные версии процессов</label>
            </div>

            <XDropDown v-model="selected.InformationInteractionsType" :filter="true"
                       :options="processTypesList"
                       class="w-full"
                       emptyFilterMessage="Нет результатов"
                       emptyMessage="Нет результатов" option-label="title"
                       placeholder="Выберите вид информационного взаимодействия"

            >
              <template #option="slotProps">{{ slotProps.option.title }} ({{ slotProps.option.version }})</template>
              <template #value="slotProps">
                <span v-if="slotProps?.value"> {{ slotProps?.value?.title }} ({{ slotProps?.value?.version }}) </span>
              </template>
            </XDropDown>

            <div v-if="selectedProcess" class="mt-2 ">
              <span style="color: var(--blue-300)">Выбран процесс: {{ selectedProcess.process?.process_view }}</span>
            </div>

            <XDropDown v-if="selected.InformationInteractionsType"
                       v-model="selected.InformationInteractionsEvent" :filter="true"
                       :options="InformationInteractionsOptions"
                       class="w-full mb-3 mt-3"
                       emptyFilterMessage="Нет результатов"
                       emptyMessage="Нет результатов" option-label="title"
                       placeholder="Выберите вид события"
            />

            <XDropDown v-if="selected.InformationInteractionsEvent"
                       v-model="selected.ElectronicDocumentsType"
                       :disabled="getElectronicDocumentsTypesByEvent?.length<=1"
                       :filter="true"
                       :options="getElectronicDocumentsTypesByEvent"
                       class="w-full"
                       emptyFilterMessage="Нет результатов"
                       emptyMessage="Нет результатов" option-label="title"
                       placeholder="Выберите тип документа"
            />

            <div class="flex mt-5">

              <Button
                v-if="selected.ElectronicDocumentsType && selected.InformationInteractionsEvent"
                class="p-button-outlined p-button-success flex-1 mr-3"
                icon="fas fa-file-signature"
                label="Заполнить данные"
                @click="getHtmlForm"
              />

              <Button
                class="p-button-outlined flex-1"
                icon="fas fa-redo"
                label="Сбросить выбор"
                @click="resetComponent"
              />

            </div>

          </div>


        </div>
      </div>
    </div>

    <div v-show="this.iframesCount" class="container" style="display:flex; flex-direction: column;">

      <Toolbar class=" mb-2 mt-2">
        <template #start>

          <Button :label="isDownload ? 'Выгрузить документ' : 'Сохранить данные'"
                  class="p-button p-button-success p-button-outlined"
                  icon="fa-solid fa-floppy-disk"
                  @click="makeXML"/>

          <Button class="p-button-outlined p-button-warning ml-2" label="Сбросить выбор"
                  @click="resetClicked"/>

          <Button v-if="this.selectedEventNumber !== 1" class="p-button-outlined p-button-danger ml-2"
                  icon="fa-solid fa-trash" label="Удалить текущее событие"
                  @click="removeCurrentEvent"/>

        </template>
        <template #end>
          <SelectButton v-model="showServiceInfo" :options="showServiceInfoOptions" option-value="val"
                        optionLabel="name" style="margin-left: 5px;"/>
        </template>
      </Toolbar>

      <div ref="iframesContainer" class="box box-middle"
           style="flex-grow: 1; display: flex; flex-direction: column;">

        <div v-if="selected.ElectronicDocumentsType === 'Универсальный документ'">

          <!--                    <Toolbar>
                                  <template #start>

                                      <Button v-for="item in this.iframesCount" :key="item"
                                              :class="(selectedEventNumber === item) ? '' : 'p-button-outlined'"
                                              :label="item.toString()" class="mr-2" @click="selectedEventNumber=item"/>
                                      <Button class="p-button-outlined mr-2" label="+" @click="addEvent"/>

                                  </template>
                              </Toolbar>-->

        </div>

      </div>


    </div>

  </div>

  <EnrichFrame ref="EnrichFrame" :auto-select-event="selected.InformationInteractionsEvent"
               :auto-select-process="selectedProcess" :context="dataFromCaller"
               :selector="selected" :source-org-guid="sourceOrgGuid"
               :source-org-title="sourceOrgTitle"/>

  <ProcessSelector ref="ProcessSelector"/>

</template>

<script>

import {EventsList, GlobalEventBus, Helper, XDropDown} from "@xnpmpackages/xcomponents";
import $ from 'jquery';
import EnrichFrame from "@/components/Constructor/EnrichFrame";
import {get} from 'idb-keyval';
import ProcessSelector from "@/components/Constructor/ProcessSelector.vue";
import * as xpath from "xpath";
import Xapi from "@xnpmpackages/xcomponents/dist/utils/xapi";


const default_selected = {
  InformationInteractionsType: null,
  InformationInteractionsEvent: null,
  show_no_actual: false,
  ElectronicDocumentsType: 'Универсальный документ',
  additionalEvents: [],

};

// noinspection JSNonASCIINames
export default {
  name: "Selector",
  provide() {
    return {
      orgInfo: () => this.orgInfo,
    }
  },
  data() {
    return {

      showServiceInfoOptions: [
        {name: 'Простой вид', val: false},
        {name: 'Полный вид', val: true},
      ],

      showServiceInfo: false,

      preloadInfoPromise: null,

      selected: Helper.methods.copyObjectByJSON(default_selected),

      html_ref: null,
      orgInfo: null,

      selectedEventNumber: 1,
      iframesCount: 0,

      dataFromCaller: null,

      processTypes: null,
      InformationInteractionsEvents: null,
      InformationInteractionsTypes: null,

      getElectronicDocumentsTypesByEvent: [],
      InformationInteractionsOptions: [],

      isNewProcess: null,
      selectedProcess: null,

    }
  },
  methods: {

    resetClicked() {

      let $this = this;
      this.$xapi.xConfirm({
        overlay: true,
        text: 'Все данные на форме будут утеряны, продолжить?',
        onSuccess: function () {
          $this.resetComponent();
        }
      });

    },

    resetComponent() {

      this.showServiceInfo = false;
      //this.InformationInteractionsTypes = null;
      //this.InformationInteractionsEvents = null;
      //this.ElectronicDocumentsTypes = null;
      this.isNewProcess = null;
      this.selectedProcess = null;
      this.selected = Helper.methods.copyObjectByJSON(default_selected);
      this.html_ref = null;
      //this.orgInfo = null;
      this.selectedEventNumber = 1;
      this.iframesCount = 0;

      $(this.$refs.iframesContainer).find("iframe").remove();

    },

    async getOrgInfo() {

      let response = await this.$xapi.post(
        '/constructor',
        {
          action: 'getSelfOrgInfo',
        },
      );

      this.orgInfo = response.data.orgInfo;

    },
    async addEvent(html, xml) {


      let iframe = document.createElement('iframe');

      iframe.srcdoc = html ?? this.html_ref;
      iframe.classList.add('dataIframe');
      iframe.onload = this.iframeLoaded;
      iframe.setAttribute('xml', xml)

      //console.log(iframe.srcdoc);

      this.$xapi.showBlockModal('Добавление события...');
      $(iframe).css('visibility', 'hidden');

      $(this.$refs.iframesContainer).append(iframe);

      this.iframesCount++;

      this.selectedEventNumber = this.iframesCount;

      let resolver
      const p = new Promise(resolve => {
        resolver = resolve
      })

      setTimeout(() => {

        const iframeBody = iframe.contentDocument.querySelector('body').querySelector('fieldset')

        if (iframeBody) {

          /*for (let index = 1; index < 15; index++)
              iframeBody.appendChild(iframe.contentDocument.createElement('br'))*/
          this._insertAttachmentsInDocument();

        }

        $(iframe).css('visibility', 'visible');
        this.$xapi.hideBlockModal();

        resolver();

      }, 1000);

      await p

    },
    async removeCurrentEvent() {

      if (this.selectedEventNumber <= 1)
        return;

      let $iframe = $('.dataIframe').get(this.selectedEventNumber - 1);
      clearInterval($($iframe).data('timer'));
      $($iframe).removeData('timer');

      this.iframesCount--;
      $($iframe).remove();
      this.selectedEventNumber--;


    },
    removeAllEvents() {

      $('.dataIframe').each((index, item) => {
        clearInterval($(item).data('timer'));
        $(item).removeData('timer');
        $(item).remove();
      })

      this.selectedEventNumber = null;
      this.iframesCount = 0;

    },
    async getHtmlForm(xmls) {


      try {

        this.$xapi.showBlockModal('Формирование документа...');

        let response = await this.$xapi.post(
          '/constructor',
          {
            action: 'getHtmlForm',
            doc_type: this.selected.ElectronicDocumentsType,
            event_type: this.selected.InformationInteractionsEvent,
          },
        );

        this.html_ref = response.data.html;

        if (this.selectedEventNumber > 0)
          this.removeAllEvents();

        await this.$nextTick()
        if (xmls && Array.isArray(xmls)) {

          for (const xml of xmls) {
            await this.addEvent(null, xml);
          }

        } else {
          await this.addEvent();
        }


      } finally {
        this.$xapi.hideBlockModal();
      }

    },

    async enrichFrame($frame) {

      this.$refs.EnrichFrame.enrichFrame($frame);

      this.switchServiceInfo(this.showServiceInfo);

    },

    async iframeLoaded($event) {

      let $iframe = $event.target;

      const xml = $iframe.getAttribute('xml')

      if (xml !== 'undefined')
        $iframe.contentWindow.fillByXml(xml)

      /*$iframe.contentWindow.document.body.querySelectorAll('.selectProcess').forEach(node => node.parentNode.removeChild(node))
      $iframe.contentWindow.document.body.querySelectorAll('.selectAbonentBtn').forEach(node => node.parentNode.removeChild(node))
      $iframe.contentWindow.document.body.querySelectorAll('[data-xsd2html2xml-namewithoutns="СсылкаАгента"].xButtonAdded').forEach(node => node.classList.remove('xButtonAdded'))*/

      $iframe.contentDocument.querySelectorAll('input[type=text]')?.forEach((item) => {
        item.classList.add('p-inputtext');
      });

      $iframe.contentDocument.querySelectorAll('input[type=number]')?.forEach((item) => {
        item.classList.add('p-inputtext');
      });

      $iframe.contentDocument.querySelectorAll('input[type=datetime-local], input[type=date]')?.forEach((item) => {
        item.classList.add('p-inputtext');
      });

      this.addControlsClasses($iframe);

      this.applyCssToIframe($iframe);
      this.enrichFrame($iframe);

      $($iframe.contentDocument).on('click', 'button', () => {
        this.enrichFrame($iframe);
      });


    },

    addControlsClasses($iframe) {

      $iframe.contentDocument.querySelectorAll('button.add')?.forEach((item) => {
        item.classList.add('p-button');
        item.classList.add('p-button-success');
        item.classList.add('p-button-sm');
        item.classList.add('p-button-outlined');
        item.classList.add('p-component');
      });

      $iframe.contentDocument.querySelectorAll('button.remove')?.forEach((item) => {
        item.classList.add('p-button');
        item.classList.add('p-button-warning');
        item.classList.add('p-button-sm');
        item.classList.add('p-button-outlined');
        item.classList.add('p-component');
      });

      $iframe.contentDocument.querySelectorAll('select')?.forEach((item) => {
        item.classList.add('p-cascadeselect');
        item.classList.add('p-component');
        item.classList.add('p-inputwrapper');
        item.classList.add('p-inputtext');
        item.classList.add('xselect');
      });

      $iframe.contentDocument.querySelectorAll('textarea')?.forEach((item) => {
        item.classList.add('p-inputtextarea');
        item.classList.add('p-inputtext');
        item.classList.add('p-component');
        item.classList.add('p-inputtextarea-resizable');
      });


    },
    async applyCssToIframe($iframe) {

      document.head.querySelectorAll('style').forEach(item => $iframe.contentWindow.document.head.appendChild(item.cloneNode(true)));
      document.head.querySelectorAll('link[rel="stylesheet"]').forEach(item => $iframe.contentWindow.document.head.appendChild(item.cloneNode(true)));


      let cssLink = document.createElement("link");
      cssLink.href = "digitDocsIframe.css";
      cssLink.rel = "stylesheet";
      cssLink.type = "text/css";

      $iframe.contentWindow.document.head.appendChild(cssLink);


      const theme = (await get('xComponents'))?.nowTheme;
      if (theme === 'light') {

        cssLink = document.createElement("link");
        cssLink.href = "digitDocsIframeLightOverride.css";
        cssLink.rel = "stylesheet";
        cssLink.type = "text/css";

        $iframe.contentWindow.document.head.appendChild(cssLink);

      }


    },

    async makeXML(suppress_errors) {

      let xmls = [];

      let errors = [];

      $('.dataIframe').each((index, $iframe) => {

        //this.$refs.EnrichFrame.fillRegistrationByDummies($iframe)

        let _disabled = $($iframe.contentDocument).find('.disabled').filter((idx, item) => {
          return !$(item).parents('[hidden]').length;
        }).prop('disabled', false);


        try {

          const htmlToXml = $iframe.contentWindow.htmlToXML($iframe.contentWindow.document.body);
          xmls.push(htmlToXml);

          if (!$iframe.contentWindow.check_all_validity()) {

            this.selectedEventNumber = (index + 1);

            errors = $iframe.contentWindow.getInvalidPaths();
            /*$($iframe.contentDocument).find('input:invalid, select:invalid, textarea:invalid').each((i, item) => {
                errors.push($(item).parent('[data-xsd2html2xml-namewithoutns]').attr('data-xsd2html2xml-xpath-without-ns'));
            });*/

            return false;

          }

        } finally {
          $(_disabled).prop('disabled', true);
        }

      });

      if (errors.length && suppress_errors !== true) {

        this.$xapi.xShowModalError({text: '<span style="color: var(--red-500)">Неверно заполнены данные документа:</span><br><br>' + errors.join("<br>")});

        if (this.isDownload) {

          const res = await this.$xapi.xConfirm({
            text: 'Документ заполнен не полностью, продолжить редактирование?',
            showOkBtn: true,
            okBtnText: 'Продолжить редактирование',
            showCancelBtn: true,
            cancelBtnText: 'Выгрузить документ',
            showHideBtn: false,
          })

          if (res?.result === 'success')
            return;

        }

      }

      let $xml;

      if (xmls?.length) {

        try {

          this.$xapi.showBlockModal('Формирование данных...');

          $xml = await this.$xapi.post(
            '/constructor',
            {
              action: 'makeXML',
              converterData: {
                docType: this.selected.ElectronicDocumentsType,
                xmls: xmls,
              },
              containerInfo: this.itemData,
            },
          );

          this.$xapi.xnotify('Данные сформированы')
          $xml = $xml.data.xml

        } finally {
          this.$xapi.hideBlockModal();
        }

      }

      //$xml = xmls[0];

      if (this.isDownload) {
        Helper.methods.downloadStringAsFile($xml, 'digital.xml');
      } else {

        let caller = window?.opener ?? window?.parent;
        if (caller) {

          console.log('Calling resolver function...')
          caller.postMessage({
            system_id: 'GosedoPortal',
            data: {
              action: 'XMLSend',
              xml: $xml,
              errors: errors,
            },
          }, '*');


        }

      }


    },

    getInformationInteractionsEventByType() {

      if (!this.selected.InformationInteractionsType)
        return [];

      let result = this.InformationInteractionsEvents
        ?.filter(item => item.version === this.selected.InformationInteractionsType.version && item.process_id === this.selected.InformationInteractionsType.id) ?? []

      if (this.selectedProcess) {
        const my_role = this.selectedProcess.process.roles.find(i => i.agentGuid === this.sourceOrgGuid)
        if (my_role) {
          result = result.filter(item => item.initiator_role_id === my_role.agentRoleId && item.event_status !== 'Инициация');
        }

      } else {
        result = result.filter(item => item.event_status === 'Инициация');
      }

      return result;

    },

    switchServiceInfo($new) {

      $('.dataIframe').each((index, iframe) => {

        iframe.contentDocument.body.querySelectorAll('.serviceInfo').forEach(item => {

          if ($new) {
            $(item).show('fast');
          } else {
            $(item).hide('fast');
          }

        });

      });

    },

    _insertAttachmentsInDocument() {

      function getEnclosureId(data) {
        return 'enclosure_' + data.id.replaceAll('-', '_') + '.' + data.name.split('.').pop()
      }

      function setValue(element, value) {
        element.value = value
        element.setAttribute('value', value)
      }

      function setValueToEnclosureBlock(element, data) {

        setValue(element.querySelector('[data-xsd2html2xml-namewithoutns="ИД"] input'), getEnclosureId(data))
        setValue(element.querySelector('[data-xsd2html2xml-namewithoutns="ИД"] input'), getEnclosureId(data))
        setValue(element.querySelector('[data-xsd2html2xml-namewithoutns="ИмяФайла"] input'), data.name)
        setValue(element.querySelector('[data-xsd2html2xml-namewithoutns="Описание"] input'), data.description)
        setValue(element.querySelector('[data-xsd2html2xml-namewithoutns="Алгоритм"] input'), '---')
        element.querySelector('[data-xsd2html2xml-namewithoutns="Значение"] input').setAttribute('value', '--')

      }

      if (!this.dataFromCaller)
        return;

      const $iframe = this.$refs.iframesContainer.querySelector('iframe')

      // ПриложенияДокумента уже открыт
      let docEnclosures = $iframe.contentDocument.querySelector('[data-xsd2html2xml-xpath-without-ns="/Документ/ДанныеДокумента/ПриложенияДокумента"]:not([hidden])')
      const enclosuresArrFromSED = this.dataFromCaller?.attachments

      if (enclosuresArrFromSED && Array.isArray(enclosuresArrFromSED) && enclosuresArrFromSED.length) {

        // открытие ПриложенияДокумента если не открыт
        if (!docEnclosures)
          $iframe.contentDocument.querySelector('[data-xsd2html2xml-xpath-without-ns="/Документ/ДанныеДокумента/ПриложенияДокумента"]').parentElement.querySelector(':scope > button.add').click();


        docEnclosures = $iframe.contentDocument.querySelector('[data-xsd2html2xml-xpath-without-ns="/Документ/ДанныеДокумента/ПриложенияДокумента"]:not([hidden])')

        const oneAttachmentAddBtn = $iframe.contentDocument.querySelector('[data-xsd2html2xml-xpath-without-ns="/Документ/ДанныеДокумента/ПриложенияДокумента/ПриложениеДокумента"]').parentElement.querySelector(':scope > button.add')

        // Блок пересчета элементов - Добавление элементов и их заполнение, если их еще нет
        enclosuresArrFromSED.forEach(item => {

          if (docEnclosures.querySelector('[value="' + getEnclosureId(item) + '"]'))
            return;

          let lastVisibleAttachmentEl = $(oneAttachmentAddBtn).prev().prev()
          lastVisibleAttachmentEl = lastVisibleAttachmentEl.length ? lastVisibleAttachmentEl[0] : null


          if (!lastVisibleAttachmentEl || lastVisibleAttachmentEl.hasAttribute('hidden') || lastVisibleAttachmentEl.querySelector('[data-xsd2html2xml-xpath-without-ns="/Документ/ДанныеДокумента/ПриложенияДокумента/ПриложениеДокумента/@ИД"] input').getAttribute('value')) {
            oneAttachmentAddBtn.click();
            lastVisibleAttachmentEl = $(oneAttachmentAddBtn).prev().prev()
            lastVisibleAttachmentEl = lastVisibleAttachmentEl.length ? lastVisibleAttachmentEl[0] : null
          }

          setValueToEnclosureBlock(lastVisibleAttachmentEl, item)

        })

        // Блок удаление элементов приложений, если таких ID не существует в СЭД
        $iframe.contentDocument.querySelectorAll('[data-xsd2html2xml-xpath-without-ns="/Документ/ДанныеДокумента/ПриложенияДокумента/ПриложениеДокумента"]').forEach(item => {

          const elValue = item.querySelector('[data-xsd2html2xml-namewithoutns="ИД"] input').getAttribute('value')

          if (!enclosuresArrFromSED.some(searchItem => getEnclosureId(searchItem) === elValue))
            item.parentElement.removeChild(item)

        });


      } else {

        // Закрытие ПриложенияДокумента если открыт
        if (docEnclosures) {
          $iframe.contentDocument.querySelector('[data-xsd2html2xml-xpath-without-ns="/Документ/ДанныеДокумента/ПриложенияДокумента"]:not([hidden]) > legend > button.remove')?.click();
        }

      }

      $iframe.contentWindow.updateIdentifiers();

    },

    async handlePostMessage(event) {

      if (event?.data?.system_id !== 'GosedoPortal')
        return;

      let $data

      /*if (event?.data) {
          try {
              $data = JSON.parse(event?.data)
              // eslint-disable-next-line no-empty
          } catch (e) {
          }
      }*/
      $data = event?.data?.data;

      if (!$data)
        return;


      switch ($data.action) {

        case 'pressSaveDocumentBtn':

          await this.makeXML($data?.data?.suppress_errors);

          break;

        case 'initData':

          this.dataFromCaller = $data?.data;

          if ($data?.data?.selector) {

            this.selected.InformationInteractionsType = $data?.data?.selector?.InformationInteractionsType
            this.selected.InformationInteractionsEvent = $data?.data?.selector?.InformationInteractionsEvent
            this.selected.ElectronicDocumentsType = $data?.data?.selector?.ElectronicDocumentsType

            await this.getHtmlForm();

          } else if (this.dataFromCaller?.linked_docs) {

            // Если есть связи с документами и у них есть активные процессы, то выбираем сразу продолжение процесса

            let response = await Xapi.post(
              '/constructor',
              {
                action: 'isLinkedDocsHasActiveProcesses',
                linked_docs: this.dataFromCaller?.linked_docs,
              },
            );

            if (response.data?.hasActiveProcesses) {
              this.continueProcessClicked();
            }


          }

          // eslint-disable-next-line no-case-declarations
          const $iframe = this.$refs.iframesContainer.querySelector('iframe')

          if ($iframe) {
            this._insertAttachmentsInDocument($iframe)
          }

          break;

        case 'addStyleTag':

          document.head.insertAdjacentHTML("beforeend", `<style>` + $data?.data?.text + `</style>`)

          break;

        case 'setIframesContent':

          this.selectedEventNumber = 1;
          this.iframesCount = 0;

          if (this.preloadInfoPromise)
            await this.preloadInfoPromise;

          $(this.$refs.iframesContainer).find("iframe").remove();

          // eslint-disable-next-line no-case-declarations
          const xmlDocument = new DOMParser().parseFromString(
            $data?.data?.xml,
            "application/xml"
          );

          // eslint-disable-next-line no-case-declarations
          const doc_tye_id = xpath.select1("/*[local-name()='Документ']/*[local-name()='ДанныеДокумента']/*[local-name()='ЗаголовокДокумента']/@*[local-name()='ВидИД']", xmlDocument)?.nodeValue;

          // eslint-disable-next-line no-case-declarations
          const docTypeId = {id: doc_tye_id, namespace: xmlDocument.documentElement.namespaceURI}

          if (docTypeId.id === 'Doc01-002-00001') {
            // Универсальный документ

            // InformationInteractionsEvents
            // InformationInteractionsTypes

            const event_id = xpath.select1('//*[local-name()="ДанныеДокумента"]/*[local-name()="СообщенияДокумента"]/child::*[1]/*[local-name()="ИнформацияОСобытии"]/@*[local-name()="ВидИД"]', xmlDocument).nodeValue;
            const event_namespace = xpath.select1('//*[local-name()="ДанныеДокумента"]/*[local-name()="СообщенияДокумента"]/child::*[1]/*[local-name()="ИнформацияОСобытии"]/..', xmlDocument).namespaceURI;

            this.selected.ElectronicDocumentsType = this.ElectronicDocumentsTypes.find(item => item.namespace === xmlDocument.documentElement.namespaceURI)
            this.selected.InformationInteractionsEvent = this.InformationInteractionsEvents.find(item => item.namespace === event_namespace && item.event_id === event_id)

            console.log(this.selected.ElectronicDocumentsType);
            console.log(this.selected.InformationInteractionsEvent);

            await this.getHtmlForm([$data?.data?.xml])


          } else {

            this.selected.ElectronicDocumentsType = this.ElectronicDocumentsTypes.find(item => item.namespace === xmlDocument.documentElement.namespaceURI)
            await this.getHtmlForm([$data?.data?.xml])

          }

          await this.getHtmlForm([$data?.data?.xml])

          break;

      }


    },

    async getRefs() {

      let response = await this.$xapi.post(
        '/constructor',
        {
          action: 'getRefs',
        },
      );

      this.processTypes = response.data.ProcessTypes;
      this.InformationInteractionsEvents = response.data.InformationInteractionsEvents;
      this.ElectronicDocumentsTypes = response.data.ElectronicDocumentsTypes;

    },

    async continueProcessClicked() {

      let $processSelectorData = await this.$refs.ProcessSelector.select(
        {
          custom_filters: {
            is_finished: false,
            docs_guids: this.dataFromCaller?.linked_docs?.map(item => item.guid),
            //process_type_id: $procKind,
            session_id: this.dataFromCaller?.session_id,
            // roles: {
            //   agentRoleId: roleId,
            //   agentGuid: this.sourceOrgGuid
            // }
          },
          selectFirstIfOnlyOne: true

        });

      if (!$processSelectorData)
        return;

      this.selectedProcess = $processSelectorData;

      this.selected.show_no_actual = true;
      this.isNewProcess = false;

      await this.$nextTick();

      this.selected.InformationInteractionsType = this?.processTypes.find(item => item.id === this.selectedProcess.process.process_type_id && item.version === this.selectedProcess.process.version);

    },

  },
  watch: {
    isNewProcess() {
      this.selected = Helper.methods.copyObjectByJSON(default_selected);
    },
    showServiceInfo($new) {

      const iframe = this.$refs.iframesContainer.querySelector("iframe")
      if (iframe) {
        if ($new) {
          iframe?.contentWindow?.document?.querySelectorAll('.transparentBlock').forEach(item => item.classList.add('noTransparent'))
        } else {
          iframe?.contentWindow?.document?.querySelectorAll('.transparentBlock').forEach(item => item.classList.remove('noTransparent'))
        }
      }

      this.switchServiceInfo($new);

    },
    selectedEventNumber($new) {

      $('.dataIframe').each((index, item) => {

        if (($new - 1) !== index)
          clearInterval($(item).data('timer'));

        $(item).removeData('timer');

        if ((index + 1) === $new) {

          $(item).show();


        } else {
          $(item).hide();
        }

      });

    },
    'selected.InformationInteractionsEvent': {

      handler(n) {

        if (!n)
          return [];

        this.getElectronicDocumentsTypesByEvent = this.ElectronicDocumentsTypes
          .filter(item => item.events_ids
            .some(i => i.version === this.selected.InformationInteractionsEvent.version && i.id === this.selected.InformationInteractionsEvent.event_id));

        this.getElectronicDocumentsTypesByEvent.unshift(this.ElectronicDocumentsTypes.find(item => !item.events_ids?.length && item.is_actual))

        this.selected.ElectronicDocumentsType = this.getElectronicDocumentsTypesByEvent[0];

      }

    },
    'selected.InformationInteractionsType': {

      handler(n, o) {

        this.selected.InformationInteractionsEvent = null;
        this.selected.ElectronicDocumentsType = null;

        if (this.selectedProcess && o) {
          this.resetComponent();
        } else {
          this.InformationInteractionsOptions = this.getInformationInteractionsEventByType();
        }


      },

    },
    getElectronicDocumentsTypesByEvent($newValue) {

      if ($newValue.length > 1) {
        this.selected.ElectronicDocumentsType = $newValue[1]
      }

    },
    InformationInteractionsOptions(n) {
      if (n && Array.isArray(n) && n.length === 1)
        this.selected.InformationInteractionsEvent = n[0];
    },

  },
  computed: {

    sourceOrgGuid() {
      return (this.dataFromCaller?.organization_guid ?? this?.orgInfo?.guid)
    },

    sourceOrgTitle() {
      return (this.dataFromCaller?.organization_title ?? this?.orgInfo?.title)
    },

    selectedElectronicDocumentsType() {

      return this.selected.ElectronicDocumentsType;
    },

    processTypesList() {

      if (this.selected?.show_no_actual) {
        return this?.processTypes;
      } else {
        return this?.processTypes?.filter(item => item?.is_actual);
      }

    },


    isDownload() {
      return this.$route.params.download;
    }

  },
  async mounted() {

    window.addEventListener('message', this.handlePostMessage);

    let caller = window?.opener ?? window?.parent;
    if (caller) {

      caller.postMessage({
        system_id: 'GosedoPortal',
        data: {
          action: 'portalReady',
        },
      }, '*');


    }

    let theme

    const updateTheme = async () => {
      switch (this.$route.query?.theme) {

        case 'light':
          GlobalEventBus.emit(EventsList.ChangeTheme, 'light')
          break;

        case 'dark':
          GlobalEventBus.emit(EventsList.ChangeTheme, 'dark')
          break;

        default:

          theme = (await get('xComponents'))?.nowTheme;
          if (theme)
            GlobalEventBus.emit(EventsList.ChangeTheme, theme)

          break;


      }
    };

    updateTheme();
    setTimeout(updateTheme, 100);
    setTimeout(updateTheme, 5000);
    setTimeout(updateTheme, 1000);
    setTimeout(updateTheme, 2000);
    setTimeout(updateTheme, 5000);

  },
  unmounted() {
    window.removeEventListener('message', this.handlePostMessage);
  },
  created() {

    this.preloadInfoPromise = Promise.all([this.getRefs(), this.getOrgInfo()]);

  },
  components: {
    ProcessSelector,
    EnrichFrame,
    XDropDown,
  }

}
</script>

<style scoped>

:deep(.dataIframe) {
  /*min-height: 60vh;*/
  display: block;
  width: 100%;
  border: 0;
  transition: height 0.15s ease-out;
  margin-top: 10px;
  flex-grow: 1;
  padding-bottom: 20px;
}

.container {
  width: 1000px;
}


</style>